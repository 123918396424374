import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BreadCrumbs from '../components/common/breadcrumbs'
import ReactPaginate from 'react-paginate';


const BlogPage = ({ data, pageContext }) => {

  const breadcrumbs = {
    'Home': '/',
    'Blog': null,
  }

  const pageCount = data.allNodeBlogPost.pageInfo.pageCount;

  const page = +pageContext.skip / 10;
  const forcePage = page;

  const onPageChange = (page) => {
    if (page.selected === 0) {
      window.location.href = '/blog';
      return;
    }
    window.location.href = '/blog/page/' + (+page.selected + 1);
  }

  return (
    <Layout pageTitle="Blog">
      <SEO title="Blog Posts" description="Drupal Developer Toronto - please review my Drupal Developer Blog posts" />
      <div className="page-blog">
        <div className="container is-desktop">
          <div className="page-breadcrumbs notification">
            <BreadCrumbs input={breadcrumbs} />
          </div>
          {
            data.allNodeBlogPost.edges.map(node => {
              return (
                <div className="teaser-blog" key={node.node.id} style={{ paddingBottom: `60px` }}>
                  <div className="teaser-title" style={{ marginBottom: `20px` }}>
                    <Link to={node.node.path.alias} style={{ fontSize: `32px`, fontWeight: `900` }}>
                      {node.node.title}
                    </Link>
                  </div>
                  <i>{node.node.created}</i>
                  <div className="teaser-content columns">
                    <div className="teaser-image column is-one-third">
                      <Img fluid={node.node.relationships.field_image.localFile.childImageSharp.fluid} />
                    </div>
                    <div className="teaser-blog column">
                      <div className="blog-contents" dangerouslySetInnerHTML={{ __html: node.node.body.summary }}></div>
                      <div className="blog-actions">
                        <Link to={node.node.path.alias} style={{ textDecoration: `underline`, fontWeight: `bold` }}>
                          Read More
                        </Link>
                        <Link to="/blog" style={{ textDecoration: `underline`, fontWeight: `bold`, marginLeft: `10px` }}>
                          blogger's Blog
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
          <div className="columns is-mobile">

            <div className="column">

              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-page'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                onPageChange={onPageChange}
                forcePage={forcePage}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default BlogPage


export const query = graphql`query($skip: Int!) {
  allNodeBlogPost(
    filter: { status: { eq: true } }
    sort: { fields: created, order: DESC }
    limit: 10
    skip: $skip
  ) {
    edges {
      node {
        id
        created(formatString: "MMMM DD, YYYY")
        title
        relationships {
          field_blog_tags {
            name
            path {
              alias
            }
          }
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        path {
          alias
        }
        body {
          value
          format
          processed
          summary
        }
      }
    }
    pageInfo {
      currentPage
      hasNextPage
      hasPreviousPage
      itemCount
      pageCount
      perPage
    }
    totalCount
  }
}`


